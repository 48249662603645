/**
 * @param {function(): *} func
 * @param {{maxDelay?: number, startingDelay?: number, timeMultiple?: number, numOfAttempts?: number, retry?: (function(e: Error, attemptNumber: number): (boolean | Promise<boolean>))}} options
 */
export async function backOff(func, options) {
    const backOff = new BackOff(func, options);

    return await backOff.execute();
}

class BackOff {
    _attemptNumber = 0;

    /**
     * @type {function(): *}
     * @private
     */
    _func;

    /**
     * @type {{maxDelay: number, startingDelay: number, timeMultiple: number, numOfAttempts: number, retry: (function(e: Error, attemptNumber: number): (boolean | Promise<boolean>))}}
     * @private
     */
    _options;

    /**
     * Constructor.
     *
     * @param {function(): *} func
     * @param {{maxDelay?: number, startingDelay?: number, timeMultiple?: number, numOfAttempts?: number, retry?: (function(e: Error, attemptNumber: number): (boolean | Promise<boolean>))}} options
     */
    constructor(func, options) {
        this._func = func;
        this._options = {
            maxDelay: Infinity,
            numOfAttempts: 10,
            retry: () => true,
            startingDelay: 100,
            timeMultiple: 2,
            ...options
        };
    }

    async execute() {
        while (!this._attemptLimitReached) {
            try {
                await this._delay();
                return await this._func();
            } catch (e) {
                this._attemptNumber++;
                const shouldRetry = await this._options.retry(e, this._attemptNumber);

                if (!shouldRetry || this._attemptLimitReached) {
                    throw e;
                }
            }
        }

        throw new Error("Something went wrong.");
    }

    get _attemptLimitReached() {
        return this._attemptNumber >= this._options.numOfAttempts;
    }

    async _delay() {
        const constant = this._options.startingDelay;
        const base = this._options.timeMultiple;
        const power = this._attemptNumber;
        const delay = constant * Math.pow(base, power);

        return new Promise(resolve => setTimeout(resolve, Math.min(delay, this._options.maxDelay)));
    }
}
