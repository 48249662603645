import AbstractStorage from "@solido/atlante-js/lib/Storage/AbstractStorage";

export default class WebSessionStorage extends AbstractStorage {
    /**
     * @inheritdoc
     */
    async hasItem(key) {
        let item;
        try {
            item = sessionStorage.getItem(key);
        } catch (e) {
            return false;
        }

        if (null === item) {
            return false;
        }

        try {
            item = this.marshaller.unmarshall(item);
            const expiry = item.expiry;
            return null === expiry || expiry >= new Date().getTime();
        } catch (e) {
            return false;
        }
    }

    async clear() {
        sessionStorage.clear();
        return true;
    }

    /**
     * @inheritdoc
     */
    async deleteItem(key) {
        sessionStorage.removeItem(key);
        return true;
    }

    /**
     * @inheritdoc
     */
    async _getItem(key) {
        let item;
        if (!(item = sessionStorage.getItem(key)) || !(item = this.marshaller.unmarshall(item))) {
            return undefined;
        }
        return item.value;
    }

    /**
     * @inheritdoc
     */
    async _save(key, value, expiry) {
        sessionStorage.setItem(key, this.marshaller.marshall({ value, expiry }));
        return true;
    }
}
