import ChainStorage from "@solido/atlante-js/lib/Storage/ChainStorage";
import InMemoryStorage from "@solido/atlante-js/lib/Storage/InMemoryStorage";
import {consola as l} from "consola/basic";
import WebSessionStorage from "../WebSessionStorage";
import fcId from "./../fcId";

const storage = new ChainStorage([
    new WebSessionStorage(),
    new InMemoryStorage(),
]);

export async function cookieConsented() {
    const item = await storage.getItem('cmp_consent_state');
    return item.isHit && item.get() === 1;
}

export default function () {


    /*
    *
    *
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");

    script.type = 'text/javascript';
    script.src = '//clickiocmp.com/t/consent_228844.js';

    script.addEventListener('load', function () {
        l.success('Load Clickio tag', script.src)
        document.documentElement.addEventListener('clickioConsentEvent', handleConsentEvent, false);
    });

    head.appendChild(script);
    */

    window._sp_queue = [];
    window._sp_ = {
        config: {
            accountId: 1839,
            baseEndpoint: 'https://cdn.privacy-mgmt.com',
            gdpr: {},
            events: {
                onConsentReady: async function (message_type, consentUUID, euconsent, info) {
                   // console.log('[event] onConsentReady', arguments);
                   // console.log('[event] message_type', message_type);
                   // console.log('[event] consentUUID', consentUUID);
                   // console.log('[event] euconsent', euconsent);
                   // console.log('[event] info',info);

                    let id = await fcId.get();
                    if ((id === null && consentUUID) || (consentUUID !== id)) {
                        await fcId.set(consentUUID);
                        l.info('FC ID set: ', consentUUID)
                    } else {
                        l.info('FC ID already set:', id)
                    }

                    let state = typeof info != 'undefined' && typeof info.applies != 'undefined' &&  info.applies === true?  1 : -1;
                    const item = await storage.getItem('cmp_consent_state');
                    item.set(state);
                    await storage.save(item);

                    l.info('Cmp consent state:', state);
                },
                onError: function () {
                    console.log('[event] onError', arguments);
                },
            }
        }
    }

    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");

    script.async = true;
    script.src = '//cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js';

    head.appendChild(script);

}
