import ChainStorage from "@solido/atlante-js/lib/Storage/ChainStorage";
import CookieStorage from "@solido/atlante-js/lib/Storage/CookieStorage";
import InMemoryStorage from "@solido/atlante-js/lib/Storage/InMemoryStorage";
import NullMarshaller from "@solido/atlante-js/lib/Storage/Marshaller/NullMarshaller";
import WebLocalStorage from "@solido/atlante-js/lib/Storage/WebLocalStorage";
import { value } from "lodash/seq";

const marshaller = new class extends NullMarshaller {
    marshall(value) {
        if (void 0 !== value.value) {
            value = value.value;
        }

        return super.marshall(value);
    }

    unmarshall(value) {
        value = super.unmarshall(value);

        return { value, expiry: null, toString: () => value };
    }
};

const wls = new WebLocalStorage();
wls.marshaller = marshaller;

const cs = new CookieStorage();
cs.marshaller = marshaller;

const storage = new ChainStorage([
  wls,
  cs,
  new InMemoryStorage(),
]);

export default {
    key: 'fc_id',
    async set(id) {
        const item = await storage.getItem(this.key);
        item.set(id);
        await storage.save(item);
    },
    async get() {
        const item = await storage.getItem(this.key);
        if (!item.isHit) {
            return null;
        }

        return String(item.get());
    },
    async remove() {
        await storage.deleteItem(this.key);
    }
};
